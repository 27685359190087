@media print {
  .hideWhenPrinting {
    display: none !important;
  }
  .reportCard {
    box-shadow: none !important;
  }
  .noPaddingTopForPrint {
    padding-top: 0 !important;
  }

  .noPageBreakOnPrint {
    break-inside: avoid-page;
  }

  // break-inside doesn't work if parent element has display:flex
  .chakra-stack {
    display: block !important;
  }

  // Prevent page-break immediately after heading
  // Source: https://stackoverflow.com/a/53742871/1317406
  h1,
  h2,
  h3 {
    break-inside: avoid-page;
    &::after {
      content: '';
      display: block;
      height: 100px;
      margin-bottom: -100px;
    }
  }

  body {
    background-color: white !important;
  }
}
