.frontpage-highlight-box {
  border: solid 1px var(--chakra-colors-brand-600);
  background-color: #f0f7fb;
  border-radius: 6px;
  margin: -10px;
  padding: 10px;
}

@media print {
  .hideWhenPrinting {
    display: none !important;
  }

  .reportCard {
    box-shadow: none !important;
  }

  .noPaddingTopForPrint {
    padding-top: 0 !important;
  }

  .noPageBreakOnPrint {
    break-inside: avoid-page;
  }

  .chakra-stack {
    display: block !important;
  }

  h1, h2, h3 {
    break-inside: avoid-page;
  }

  h1:after, h2:after, h3:after {
    content: "";
    height: 100px;
    margin-bottom: -100px;
    display: block;
  }

  body {
    background-color: #fff !important;
  }
}

.QAndA-card small {
  color: #757575;
}

.QAndA-card .center-disclaimer {
  text-align: center;
  width: 100%;
}

.QAndA-card .yed-center {
  text-align: center;
}

.QAndA-card .turn-phone {
  text-align: center;
  color: inherit;
  background-color: #80c6ff;
  border-radius: 15px;
  margin-top: 1rem;
  padding: 5px 15px;
  display: inline-block;
}

@media only screen and (min-width: 450px) {
  .QAndA-card .turn-phone {
    display: none;
  }
}

.QAndA-card .question-disclaimer {
  text-align: center;
  color: inherit;
  background-color: silver;
  border-radius: 15px;
  margin-bottom: 1rem;
  padding: 5px 15px;
  display: inline-block;
}

.QAndA-card .emoji {
  width: auto;
  height: 1.5rem;
  margin-left: .2rem;
  display: inline-block;
}

.QAndA-card .yed-subtitle {
  font-size: 1.1rem;
  line-height: 1.2;
  font-family: var(--chakra-fonts-heading);
  font-weight: var(--chakra-fontWeights-bold);
  color: var(--chakra-colors-brand-600);
  overflow-wrap: anywhere;
  margin-top: 1rem;
}

.QAndA-card .duodecim h3 {
  margin-top: 1.5rem;
  margin-bottom: .5rem;
  font-size: 1.1rem;
  font-weight: 700;
}

.QAndA-card .duodecim a span.text {
  font-size: 80%;
  display: none;
  position: relative;
  top: -.5em;
}

.QAndA-card .duodecim table {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 1.5rem 1px;
}

.QAndA-card .duodecim table caption {
  max-width: 75vw;
  margin-bottom: 1rem;
  font-weight: 700;
}

.QAndA-card .duodecim table th {
  color: #fff;
  vertical-align: top;
  text-align: inherit;
  text-align: -webkit-match-parent;
  background-color: #005293;
  padding: .5rem;
  font-weight: 700;
}

.QAndA-card .duodecim table td {
  text-align: left;
  vertical-align: top;
  border: 1px solid #ccc;
  padding: 15px;
  font-size: .875em;
}

.QAndA-card .duodecim table tr:nth-child(odd) {
  background-color: #f5f5f5;
}

.QAndA-card .duodecim table tr:nth-child(2n) {
  background-color: #fff;
}

.QAndA-card .duodecim p + p {
  margin-top: 1rem;
}

.QAndA-card .duodecim li {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.QAndA-card .duodecim .table-container {
  max-width: 75vw;
  overflow-x: auto;
}

.QAndA-card .youtube-container {
  max-width: 640px;
  margin: 0 auto;
}

.QAndA-card .youtube-iframe-wrapper {
  height: 0;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-top: 25px;
  padding-bottom: 56.25%;
  position: relative;
}

.QAndA-card .youtube-iframe-wrapper iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.QAndA-card .druglist summary {
  cursor: pointer;
  margin-bottom: 1rem;
  text-decoration: underline;
}

.QAndA-card .druglist ul {
  margin-bottom: 1rem;
}

.QAndA-card .empty-row {
  height: 1rem;
}

.reportCard .adsume-limits {
  list-style-type: "- ";
  list-style-position: inside;
}

.payment-terms {
  text-align: center;
  max-width: 500px;
  font-size: large;
}

.payment-terms a {
  font-weight: 600;
  text-decoration: underline;
}

@font-face {
  font-family: Quicksand;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("quicksand-vietnamese-500-normal.b01d417a.woff2") format("woff2"), url("quicksand-all-500-normal.aa5f328d.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Quicksand;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("quicksand-latin-ext-500-normal.54724b79.woff2") format("woff2"), url("quicksand-all-500-normal.aa5f328d.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Quicksand;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("quicksand-latin-500-normal.d4221b77.woff2") format("woff2"), url("quicksand-all-500-normal.aa5f328d.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Quicksand;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("quicksand-vietnamese-700-normal.04af75d5.woff2") format("woff2"), url("quicksand-all-700-normal.cf75ee85.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Quicksand;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("quicksand-latin-ext-700-normal.3ae406d4.woff2") format("woff2"), url("quicksand-all-700-normal.cf75ee85.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Quicksand;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("quicksand-latin-700-normal.57a23056.woff2") format("woff2"), url("quicksand-all-700-normal.cf75ee85.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/*# sourceMappingURL=index.fe37213c.css.map */
