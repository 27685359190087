.QAndA-card {
  small {
    color: #757575;
  }

  .center-disclaimer {
    width: 100%;
    text-align: center;
  }

  .yed-center {
    text-align: center;
  }

  .turn-phone {
    display: inline-block;
    text-align: center;
    background-color: #80c6ff;
    color: inherit;
    padding: 5px 15px 5px 15px;
    border-radius: 15px;
    margin-top: 1rem;
    margin-top: 1rem;
    @media only screen and (min-width: 450px) {
      display: none;
    }
  }

  .question-disclaimer {
    display: inline-block;
    text-align: center;
    background-color: silver;
    color: inherit;
    padding: 5px 15px 5px 15px;
    border-radius: 15px;
    margin-bottom: 1rem;
  }

  .emoji {
    height: 1.5rem;
    margin-left: 0.2rem;
    width: auto;
    display: inline-block;
  }

  .yed-subtitle {
    font-size: 1.1rem;
    line-height: 1.2;
    font-family: var(--chakra-fonts-heading);
    font-weight: var(--chakra-fontWeights-bold);
    color: var(--chakra-colors-brand-600);
    overflow-wrap: anywhere;
    margin-top: 1rem;
  }

  .duodecim {
    h3 {
      font-size: 1.1rem;
      font-weight: 700;
      margin-top: 1.5rem;
      margin-bottom: 0.5rem;
    }

    // Style references as subscripts
    a span.text {
      position: relative;
      top: -0.5em;
      font-size: 80%;
      // Hide references for now
      display: none;
    }

    table {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
      margin-left: 1px;
      margin-right: 1px;
      border-collapse: collapse;
      border-spacing: 0;

      caption {
        max-width: 75vw;
        margin-bottom: 1rem;
        font-weight: 700;
      }

      th {
        background-color: #005293;
        color: #fff;
        font-weight: 700;
        padding: 0.5rem;
        vertical-align: top;
        text-align: inherit;
        text-align: -webkit-match-parent;
      }

      td {
        padding: 15px;
        border: 1px solid #ccc;
        text-align: left;
        vertical-align: top;
        font-size: 0.875em;
      }

      tr:nth-child(odd) {
        background-color: #f5f5f5;
      }

      tr:nth-child(2n) {
        background-color: #fff;
      }
    }

    p + p {
      margin-top: 1rem;
    }

    li {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }

    .table-container {
      overflow-x: auto;
      max-width: 75vw;
    }
  }

  .youtube-container {
    max-width: 640px;
    margin: 0 auto;
  }

  .youtube-iframe-wrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .youtube-iframe-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .druglist {
    summary {
      cursor: pointer;
      text-decoration: underline;
      margin-bottom: 1rem;
    }

    ul {
      margin-bottom: 1rem;
    }
  }

  .empty-row {
    height: 1rem;
  }
}

.reportCard {
  .adsume-limits {
    list-style-position: inside;
    list-style-type: '- ';
  }
}
